import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';

// Hooks

import GetWindowSize from '../../hooks/GetWindowSize';

// Components

import PageWrapper from '../../components/layout/PageWrapper/PageWrapper';
import Button from '../../components/UI/Button/Button';

import './Styles.scss';

export default function Home({isButtonDisabled}) {
    const { windowSize } = GetWindowSize();
    const [showVideo, setShowVideo] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        let W = window.innerWidth;
        let H = window.innerHeight;
        const canvas = document?.getElementById("canvas");
        const context = canvas?.getContext("2d");
        const maxConfettis = windowSize?.innerWidth >= 1201 ? 80 : 40;
        const particles = [];

        const possibleColors = ["OliveDrab", "Gold", "Gold", "SandyBrown"];

        function randomFromTo(from, to) {
        return Math.floor(Math.random() * (to - from + 1) + from);
        }

        function confettiParticle() {
        this.x = Math.random() * W; // x
        this.y = Math.random() * H - H; // y
        this.r = randomFromTo(11, 33); // radius
        this.d = Math.random() * maxConfettis + 11;
        this.color =
            possibleColors[Math.floor(Math.random() * possibleColors.length)];
        this.tilt = Math.floor(Math.random() * 33) - 11;
        this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
        this.tiltAngle = 0;

        this.draw = function () {
            context.beginPath();
            context.lineWidth = this.r / 2;
            context.strokeStyle = this.color;
            context.moveTo(this.x + this.tilt + this.r / 3, this.y);
            context.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5);
            return context.stroke();
        };
        }

        function Draw() {
        const results = [];

        // Magical recursive functional love
        requestAnimationFrame(Draw);

        context.clearRect(0, 0, W, window.innerHeight);

        for (var i = 0; i < maxConfettis; i++) {
            results.push(particles[i].draw());
        }

        let particle = {};
        let remainingFlakes = 0;
        for (var i = 0; i < maxConfettis; i++) {
            particle = particles[i];

            particle.tiltAngle += particle.tiltAngleIncremental;
            particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 4;
            particle.tilt = Math.sin(particle.tiltAngle - i / 3) * 15;

            if (particle.y <= H) remainingFlakes++;

            // If a confetti has fluttered out of view,
            // bring it back to above the viewport and let if re-fall.
            if (particle.x > W + 30 || particle.x < -30 || particle.y > H) {
            particle.x = Math.random() * W;
            particle.y = -30;
            particle.tilt = Math.floor(Math.random() * 10) - 20;
            }
        }

        return results;
        }

        window.addEventListener(
        "resize",
        function () {
            W = window.innerWidth;
            H = window.innerHeight;
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        },
        false
        );

        // Push new confetti objects to `particles[]`
        for (var i = 0; i < maxConfettis; i++) {
        particles.push(new confettiParticle());
        }

        // Initialize 
        if (canvas) {
        canvas.width = W;
        canvas.height = H;
        Draw();
        }
      });

    return (
        <PageWrapper isButtonDisabled={isButtonDisabled} clearDisclaimer noContainer={windowSize?.innerWidth >= 1201 ? false : true} image={windowSize?.innerWidth >= 1201 ? 'cars' : null}>
            <canvas id="canvas" className="home-canvas"></canvas>
            <div className='home-page'>
                <div className='intro-heading'>
                    {windowSize?.innerWidth < 1201 &&
                    <img src={require('./Images/img-kv.jpg')} alt={"Crisol te Monta"} />}
                </div>
                <div className="intro-content">
                    <div className="video-frame">

                        <iframe width={windowSize?.innerWidth >= 600 ? "380" : '100%'} height={windowSize?.innerWidth >= 600 ? "220" : '180'} src="https://www.youtube.com/embed/H9LTNt5QfNc?si=yP9JdSvpYvNUB9m6&autoplay=1&mute=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

                    </div>
       
                    {windowSize?.innerWidth >= 1201 &&
                    <img src={require('./Images/img-mecanicas.png')} alt={"Mecánicas"} />}


                    {windowSize?.innerWidth < 1201 &&
                    <img src={require('./Images/img-mecanicas-mobile.png')} alt={"Mecánicas"} />}

                    {/* <div className='call-to-action'>

                        {!isButtonDisabled &&
                            <Button
                                action={() => navigate('/registro')}
                                label={'Participa ya'}
                                altColor={windowSize?.innerWidth >= 1201 ? false : true}
                            />
                        }
                    </div> */}
                    
                </div>
            </div>

            {/* {showVideo &&
            <div className='video-modal'>
                <div className='video-modal-inner'>

                    <button onClick={() => setShowVideo(false)}>X</button>

                    <div class="video-embed">

                        <iframe width="560" height="315" src="https://www.youtube.com/embed/dBFeisgRUgg?si=vHmWyETJKPvfjf68" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                    </div>

                </div>
            </div>} */}
        </PageWrapper>
    )
}
