import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';

// Components

import PageWrapper from '../../components/layout/PageWrapper/PageWrapper';

import './Styles.scss';

export default function Contact() {
    return (
        <PageWrapper noContainer={true}>
            <div className='container'>
                <div className='contact-page'>
                    <div className='contact-heading'>
                        <div className='contact-subheading'>
                            <h2>Contacto</h2>
                        </div>
                    </div>
                </div>
            </div>
        
            <div className='contact-info'>
                <div className='container'>
                    <div className='contact-info-details'>
                        <div className='contact-info-heading'>
                            <h2>Esta es una promoción realizada por</h2>
                            <p className='contact-info-agency-name'><strong>MercaSID, S.A.</strong></p>
                            <p>
                                <FontAwesomeIcon className='contact-info-icon' icon={faLocationDot} />
                                Av. Maximo Gómez #182<br/>Santo Domingo. Distrito Nacional.<br/>República Dominicana
                            </p>
                            <p>
                                <FontAwesomeIcon className='contact-info-icon' icon={faPhone} />
                                <a href="tel:8097032431">809-703-2431</a> / <a href="tel:8099473448">809-947-3448</a>
                            </p>
                            <p>
                                <a href="mailto:crisoltemonta@gmail.com">
                                    <FontAwesomeIcon className='contact-info-icon' icon={faEnvelope} />
                                    crisoltemonta@gmail.com
                                </a>
                            </p>

                            <p className='social-media'>
                                <span>Siguenos en nuestras redes</span>
                                <a className='social-media-icon' href="https://twitter.com/CrisolRD" target="_blank">
                                    <img src={require('./Images/icn-twitter.png')} alt="Twitter / X" />
                                </a>
                                <a className='social-media-icon' href="https://www.instagram.com/crisolrd/" target="_blank">
                                    <img src={require('./Images/icn-instagram.png')} alt="Instagram" />
                                </a>
                                <a className='social-media-icon' href="https://www.facebook.com/aceitescrisol/" target="_blank">
                                    <img src={require('./Images/icn-facebook.png')} alt="Facebook" />
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </PageWrapper>
    )
}
