import React, {useState, useEffect} from 'react'

// Services

import WinnersService from '../../../services/winners.service';

// Components

import Loader from '../../UI/Loader/Loader';
import Pagination from '../../UI/Pagination/Pagination';
import NoEntriesMessage from '../../UI/NoEntriesMessage/NoEntriesMessage';

// Styles

import './Styles.scss';

export default function WinnerList() {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState();
    const [data, setData] = useState();

    // Get Winners With Pagination

    const getWinners = async (page) => {
        setIsLoading(true);
        setIsError(null);
        setData(null);
        
        try {
        
            const response = await WinnersService.getWinners(page);

            setData(response.data);
            setIsLoading(false);
        
        } catch (error) {
            setIsLoading(false);
            setIsError(error.response?.data?.message || error.message);
        }
    
    }

    // Get all the winners on load

    useEffect(() => {
        getWinners();
    }, [])

    return (
        <div className='winners-list-container'>
            {(isLoading || isError) &&
            <Loader
                message={'Cargando...'}
                error={isError}
                action={() => getWinners()}
            />}
            
            {!isLoading && !isError && data?.data && data?.data?.length > 0 &&
            // {!isLoading && !isError &&
            <>
                <div className='winners-list'>
                    { data?.data?.map((entry, i) =>
                        <Entry entry={entry} key={i} />
                    )}
                </div>

                <Pagination
                    action={getWinners}
                    pagination={data}
                />
                
            </>}

            {!isLoading && !isError && data?.data && data?.data?.length === 0 &&
                <NoEntriesMessage message={'No se encontraron ganadores'} />
            }
        </div>
    )
}

const Entry = ({entry}) => (
    <div className='winners-list-entry'>
        <div className='winners-list-entry-image'>
            <div className='winners-list-entry-image-inner'>
                {entry.image ? (
                    <img src={entry.image} alt={entry.name} />
                ) : (
                    <img src={require('./Images/img-winner.png')} className='winners-list-entry-no-winner' alt={entry.name} />
                )}
            </div>
        </div>
        <div className='winners-list-entry-info'>
            <p className='winners-entry-name'>{entry.full_name}</p>
            <p>Cédula: {entry.document_number}</p>
            <p>{entry.winner_product}</p>
            <p>{entry.establishment}</p>
        </div>

    </div>
);
