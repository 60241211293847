// require('dotenv').config();

// API Root Url

export const API_URL = process.env.REACT_APP_API_URL;

const PRODUCTS = [
    'Crisol Soya 64 oz',
    'Crisol Soya 128 oz',
    // 'Crisol Soya 250 oz',
    'Crisol Más 64 oz',
    'Crisol Más 128 oz',
];

export const PRODUCTS_FORMATTED = PRODUCTS.map(product => ({
    value: product,
    label: product
}));

const PROVINCES = [
    'Azua',
    'Bahoruco',
    'Barahona',
    'Dajabón',
    'Distrito Nacional',
    'Duarte',
    'Elías Piña',
    'El Seibo',
    'Espaillat',
    'Hato Mayor',
    'Hermanas Mirabal',
    'Independencia',
    'La Altagracia',
    'La Romana',
    'La Vega',
    'María Trinidad Sánchez',
    'Monseñor Nouel',
    'Monte Cristi',
    'Monte Plata',
    'Pedernales',
    'Peravia',
    'Puerto Plata',
    'Samaná',
    'Sánchez Ramírez',
    'San Cristóbal',
    'San José de Ocoa',
    'San Juan',
    'San Pedro de Macorís',
    'Santiago',
    'Santiago Rodríguez',
    'Santo Domingo',
    'Valverde',
];

export const PROVINCES_FORMATTED = PROVINCES.map(province => ({
    value: province,
    label: province
}));