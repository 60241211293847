import React from 'react'
import Header from '../Header/Header'

import './Styles.scss';

const images = {
  main: require('./Images/bg-main.jpg'),
  cars: require('./Images/bg-cars.jpg'),
}

export default function PageWrapper({children, isButtonDisabled, image, headerBorder, altBackground, noContainer, clearDisclaimer}) {
  return (
    <div
      className={`page-wrapper ${altBackground ? 'page-wrapper-alt-background' : ''}`}
      style={image ? { backgroundImage: "url(" + images[image] + ")" } : { backgroundImage: "url(" + images.main + ")" }}
    >
      <Header isButtonDisabled={isButtonDisabled} headerBorder={headerBorder} />

      <div className={`${noContainer ? '' : 'container'}`}>
          {children}
      </div>

      <div className='disclaimer-crisol' style={!clearDisclaimer ? {backgroundColor: '#005315'} : {}}>
        <p><strong>Promoción válida del 1ro. de mayo al 31 de julio del 2024.</strong> Registrado en Pro Consumidor bajo el No. CRS-0216/2024</p>
      </div>

    </div>
  )
}
