export const PRODUCTS_DATA = [
    {
        name: 'Crisol Soya',
        image: require('./Images/item-crisol-soya.png'),
        description: 'Dale el toque dominicano a tu cocina con aceite Crisol 100% puro de soya, enriquecido con vitamina E y ácidos grasos esenciales. Crisol soya es el ingrediente esencial en todas tus comidas, perfecto para preparar desde tostones hasta arroces y guisos.',
        extraInfo: '¡Ponle el sabor auténtico dominicano a tus platos con Crisol Soya!',
        hasBackground: false,
    },
    {
        name: 'Crisol Más',
        image: require('./Images/img-crisol-mas.png'),
        description: '¡Disfruta tus comidas favoritas con confianza! Con Crisol Más, tienes un aceite de canola 100% que promueve el bienestar, con 0% colesterol, 0% grasas trans y 0% carbohidratos. Es perfecto para los dominicanos que buscan equilibrar sabor y salud en todas sus recetas.',
        hasBackground: true,
    },
    // {
    //     name: 'Crisol Criollo',
    //     image: require('./Images/img-crisol-criollo.png'),
    //     imagePres: require('./Images/img-crisol-criollo-pres.png'),
    //     description: 'Aceite comestible 100% puro de canola con sabor ajo, cebolla y orégano, para darle a sus platos un toque exquisito, realzando el sabor característico dominicano. Ideal para arroces, fritos verdes, pastas, sofritos, etc. El aceite de canola contiene la mitad de grasas saturadas que el aceite vegetal normal y aporta más Omegas 3, 6 y 9 que son ácidos grasos esenciales para la buena salud.',
    //     hasBackground: false,
    // },
]