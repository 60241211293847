import React from 'react';

// Components

import PageWrapper from '../../components/layout/PageWrapper/PageWrapper';

import './Styles.scss';

// Data

import { PRODUCTS_DATA } from './Data';

export default function Products() {
    return (
        <PageWrapper noContainer={true}>
            <div className='container'>
                <div className='products-page'>
                    <div className='products-heading'>
                        <div className='products-subheading'>
                            <h2>Productos</h2>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Products */}
            
            {PRODUCTS_DATA && PRODUCTS_DATA.map((entry, i) => (
                entry.hasBackground ? (
                    <ProductEntry key={i} entry={entry} />
                ) : (
                    <ProductEntrySimple key={i} entry={entry} />
                )
            ))}
        </PageWrapper>
    )
}

const ProductEntrySimple = ({entry}) => (
    <div className='products-entry products-entry-simple'>
        <div className='container'>
            <div className='product-details'>
                <div className='products-heading'>
                    <h1>{entry.name}</h1>
                    <p>{entry.description}</p>
                    <p>{entry.extraInfo}</p>
                    {/* <p><strong>Presentaciones:</strong></p> */}
                    {/* <img className='presentations-image' src={entry.imagePres} alt={entry.name} /> */}
                </div>
                <div className='product-image'>
                    <img src={entry.image} alt={entry.name} />
                </div>
            </div>
        </div>
    </div>
)


const ProductEntry = ({entry}) => (
    <div
        className='products-entry'
        style={entry.backgroundImage ? { backgroundImage: "url(" + entry.backgroundImage + ")" } : {}}
    >
        <div className='container'>
            <div className='product-details'>
                <div className='product-image'>
                    <img src={entry.image} alt={entry.name} />
                </div>
                <div className='products-heading'>
                    <h1>{entry.name}</h1>
                    <p>{entry.description}</p>
                    <p>{entry.extraInfo}</p>
                    {/* <p><strong>Presentaciones:</strong></p>
                    <img className='presentations-image' src={entry.imagePres} alt={entry.name} /> */}
                </div>
            </div>
        </div>
    </div>
)