import React from 'react';

// Components

import PageWrapper from '../../components/layout/PageWrapper/PageWrapper';
import WinnerList from '../../components/lists/WinnerList/WinnerList';

// Styles

import './Styles.scss';

export default function Winners() {
    return (
        <PageWrapper noContainer={true}>
            <div className='winners-page-wrapper'>
                <div className="container">
                    <div className='winners-heading'>
                        <div className='winners-subheading'>
                            <h2>Ganadores</h2>
                            <p style={{marginBottom: 0, paddingBottom: 0, paddingTop: '0.5rem', fontSize: '1.5rem'}}>Premios Quincenales</p>
                        </div>
                    </div>
                </div>

                <div className="winners-page">
                    <div className='winners-page-inner'>

                        <WinnerList />

                    </div>
                </div>
            </div>

        </PageWrapper>
    )
}
