import React, {useState, useRef, useEffect} from 'react'
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

import './Styles.scss';

export default function Header({headerBorder, isButtonDisabled}) {
    const [showMainNav, setShowMainNav] = useState(false);

    return (
    <div className={`main-header ${headerBorder ? 'main-header-with-border' : ''}`}>
        <div className='container'>
            <h1 className='brand'>
                <NavLink to={'/'} end>
                    <img src={require('./Images/img-logo-brand.png')} alt="Cristol" />
                </NavLink>
            </h1>

            <button type="button" id="main-nav-toggle" className='main-nav-toggle' onClick={() => setShowMainNav(!showMainNav)}>
                <FontAwesomeIcon className='main-nav-toggle-icon' icon={showMainNav ? faXmark : faBars} />
            </button>

            <nav id="main-nav" className={showMainNav ? 'main-nav main-nav-show' : 'main-nav'}>
                <ul>
                    {/* {!isButtonDisabled &&
                        <li>
                            <NavLink to={'/registro'}>Registro</NavLink>
                        </li>
                    } */}
                    <li>
                        <NavLink to={'/productos'}>Productos</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/calendario'}>Calendario</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/ganadores'}>Ganadores</NavLink>
                    </li>
                    <li>
                        <NavLink to={'/contacto'}>Contacto</NavLink>
                    </li>
                </ul>
            </nav>

        </div>
    </div>
  )
}
