
import api from "../config/api";
import BaseService from './base-service';

export default class WinnersService extends BaseService {

    // Get Winners With Pagination ====================================================================================================

    /**
        * @param page optional page number
        * @description Gets the list of the winners with pagination
    */

    static getWinners(page) {
        const url = page ? `/winners?page=${page}` : `/winners`;

        return api.get(url);
    }
    
}
