import React, {useRef, useEffect} from 'react'
import { useLocation } from 'react-router-dom';

// Audio

import audioSong from '../../assets/s/crisol-te-monta-radio.mp3';

export default function AudioPlayer() {
    let location = useLocation();
    const audioRef = useRef();

    // useEffect(() => {
    //     audioRef.current.addEventListener("canplaythrough", () => {
    //         audioRef.current.play().catch(e => {
    //             window.addEventListener('click', () => {
    //             audioRef.current.play()
    //             }, { once: true })
    //         })
    //     });
    // })

    useEffect(() => {
        if (location?.pathname !== '/') {
            audioRef.current.play().catch(e => {
                window.addEventListener('click', () => {
                audioRef.current.play()
                }, { once: true })
            })
        } else {
            audioRef.current.pause()
        }
    }, [location])
    
  return (
    <audio ref={audioRef} src={audioSong} style={{display: "none"}} loop></audio>
  )
}
