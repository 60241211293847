import React, {useState} from 'react';

// Components

import PageWrapper from '../../components/layout/PageWrapper/PageWrapper';

import './Styles.scss';

// Data

import { CALENDAR_DATA } from './Data';
import { CALENDAR_INDEPENDENT_DATA } from './DataIndepenent';

export default function Calendar() {
    const [toggleCalendar, setToggleCalendar] = useState(true);

    return (
        <PageWrapper noContainer={true}>
            <div className='calendar-page-wrapper'>
                <div className="container">
                    <div className='calendar-heading'>
                        <div className='calendar-subheading'>
                            <h2>Calendario</h2>
                            <p>Ven y participa de nuestra ruleta y gánate premios instantáneos al consumir aceite Crisol en sus productos participantes, conoce la próximas fechas. ¡Te esperamos!</p>
                        </div>
                    </div>
                </div>
                
                <div className="calendar-page">

                    <div className='calendar-switch'>
                        <button className={toggleCalendar === true ? 'calendar-switch-active' : ''} onClick={() => setToggleCalendar(true)} disabled={toggleCalendar === true}>Supermercados</button>
                        <button className={toggleCalendar === false ? 'calendar-switch-active' : ''} onClick={() => setToggleCalendar(false)} disabled={toggleCalendar === false}>Supermercados Independientes</button>
                    </div>


                    {/* Locales */}

                    {toggleCalendar &&                    
                    <div className='calendar-page-inner'>
                        {CALENDAR_DATA && CALENDAR_DATA.map((entry, i) => (
                        <div className='calendar-entries-wrapper' key={i}>
                            <div className='calendar-entries-heading'>
                                <h3>{entry.month}</h3>
                                <div className='calendar-entries-schedule'>
                                    <p>Lunes a Viernes de 4:00pm a 8:00pm</p>
                                    <p>Sabados y Domingos de 10:00am a 2:00pm</p>
                                </div>
                            </div>

                            <div className='calendar-entries'>
                                {entry?.activations?.map((item, index) => (
                                    <div className='calendar-entry' key={index}>
                                        <p>{`${item.date} de ${entry.month}`}</p>
                                        <p className='calendar-entry-locale'>{item.locale}</p>
                                        <p className='calendar-entry-province'>{item.province}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        ))}
                    </div>}


                    {/* Independent Locales */}
                    
                    {!toggleCalendar &&
                    <div className='calendar-page-inner'>
                        {CALENDAR_INDEPENDENT_DATA && CALENDAR_INDEPENDENT_DATA.map((entry, i) => (
                        <div className='calendar-entries-wrapper' key={i}>
                            <div className='calendar-entries-heading'>
                                <h3>{entry.month}</h3>
                                <div className='calendar-entries-schedule'>
                                    <p>Lunes a Viernes de 10:00am a 2:00pm</p>
                                    <p>Sabados y Domingos de 10:00am a 2:00pm</p>
                                </div>
                            </div>

                            <div className='calendar-entries'>
                                {entry?.activations?.map((item, index) => (
                                    <div className='calendar-entry' key={index}>
                                        <p>{`${item.date} de ${entry.month}`}</p>
                                        <p className='calendar-entry-locale'>{item.locale}</p>
                                        <p className='calendar-entry-province'>{item.province}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        ))}
                    </div>}
                    
                </div>



            </div>

        </PageWrapper>
    )
}
