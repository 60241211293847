export const CALENDAR_DATA = [
    { 
        month: 'Mayo',
        activations: [
            { locale: 'Jumbo Luperon', province: 'Santo Domingo', date: '1, 2, 30 y 31' },
            { locale: 'Sirena Churchill', province: 'Santo Domingo', date: '1, 2, 30 y 31' },
            { locale: 'Nacional El Millon', province: 'Santo Domingo', date: '1, 2, 30 y 31' },
            // { locale: 'Sirena Luperon', province: 'Santo Domingo', date: '1 y 2' },
            { locale: 'Carrefour Aut. Duarte', province: 'Santo Domingo', date: '1, 2, 30 y 31' },
            { locale: 'Jumbo Agora Mall', province: 'Santo Domingo', date: '1 y 2' },
            { locale: 'Nacional Arroyo Hondo', province: 'Santo Domingo', date: '1 y 2' },
            { locale: 'Nacional Tiradentes', province: 'Santo Domingo', date: '1 y 2' },
            // { locale: 'Sirena Market Arroyo Hondo', province: 'Santo Domingo', date: '1 y 2' },
            { locale: 'Sirena Galeria 360', province: 'Santo Domingo', date: '3, 4, 30 y 31' },
            { locale: 'Jumbo San Isidro', province: 'Santo Domingo', date: '10 y 11' },
            { locale: 'Jumbo Megacentro', province: 'Santo Domingo', date: '10 y 11' },
            // { locale: 'Sirena Charles de Gaulle', province: 'Santo Domingo', date: '10 y 11' },
            // { locale: 'Sirena San Isidro', province: 'Santo Domingo', date: '10 y 11' },
            // { locale: 'Sirena Venezuela', province: 'Santo Domingo', date: '10 y 11' },
            { locale: 'Nacional Bella Vista', province: 'Santo Domingo', date: '15 y 16' },
            // { locale: 'Sirena Sarasota', province: 'Santo Domingo', date: '15 y 16' },
            { locale: 'Plaza Lama 27 Febrero', province: 'Santo Domingo', date: '15 y 16' },
            { locale: 'Ole 27 Febrero', province: 'Santo Domingo', date: '15 y 16' },
            // { locale: 'Sirena Lope de Vega', province: 'Santo Domingo', date: '15 y 16' },
            // { locale: 'Sirena Aut. Duarte', province: 'Santo Domingo', date: '17 y 18' },
            // { locale: 'Sirena Villa Mella', province: 'Santo Domingo', date: '17 y 18' },
            { locale: 'Plaza Lama Duarte', province: 'Santo Domingo', date: '17 y 18' },
            { locale: 'Plaza Lama Aut. Duarte', province: 'Santo Domingo', date: '17 y 18' },
            { locale: 'Ole Duarte', province: 'Santo Domingo', date: '17 y 18' },
        ]
    },
    { 
        month: 'Junio',
        activations: [
            { locale: 'Jumbo La Romana', province: 'La Romana', date: '5, 6, 7 y 8' },
            // { locale: 'Sirena La Romana', province: 'La Romana', date: '5, 6, 7 y 8' },
            { locale: 'Iberia Romana', province: 'La Romana', date: '5, 6, 7 y 8' },
            { locale: 'Zaglul Duarte', province: 'San Pedro de Macoris', date: '5, 6, 7 y 8' },
            { locale: 'Iberia San Pedro', province: 'San Pedro de Macoris', date: '5, 6, 7 y 8' },
            // { locale: 'Sirena La Vega', province: 'La Vega', date: '14 y 15' },
            { locale: 'Jumbo La Vega', province: 'La Vega', date: '14 y 15' },
            // { locale: 'Sirena Moca', province: 'Moca', date: '14 y 15' },
            { locale: 'Jumbo Moca', province: 'Moca', date: '14 y 15' },
            // { locale: 'Sirena Embrujo', province: 'Santiago', date: '14 y 15' },
            { locale: 'Sirena Puerto Plata', province: 'Puerto Plata', date: '20 y 21' },
            { locale: 'Jumbo Puerto Plata', province: 'Puerto Plata', date: '20 y 21' },
            { locale: 'Sirena  San Francisco', province: 'San Francisco de Macoris', date: '20 y 21' },
            { locale: 'Jumbo San Francisco', province: 'San Francisco de Macoris', date: '20 y 21' },
            { locale: 'Jumbo Santiago', province: 'Santiago', date: '20 y 21' },
            { locale: 'Nacional Santiago', province: 'Santiago', date: '27, 28, 29, y 30' },
            { locale: 'Sirena Bartolome Colon', province: 'Santiago', date: '27, 28, 29, y 30' },
            { locale: 'Sirena Calle El Sol', province: 'Santiago', date: '27, 28, 29, y 30' },
            { locale: 'Sirena Estrella Sadhala', province: 'Santiago', date: '27, 28, 29, y 30' },
            { locale: 'Plaza Lama Santiago', province: 'Santiago', date: '27, 28, 29, y 30' },
        ]
    },
    {
        month: 'Julio',
        activations: [
            { locale: 'Jumbo Luperon', province: 'Santo Domingo', date: '5, 6, 30 y 31' },
            { locale: 'Sirena Churchill', province: 'Santo Domingo', date: '5, 6, 10, 11, 12, 13, 30 y 31' },
            { locale: 'Nacional El Millon', province: 'Santo Domingo', date: '5, 6, 30 y 31' },
            { locale: 'Sirena Luperón', province: 'Santo Domingo', date: '5, 6, 10 y 11' },
            { locale: 'Carrefour Aut. Duarte', province: 'Santo Domingo', date: '5, 6, 30 y 31' },
            { locale: 'Jumbo Agora Mall', province: 'Santo Domingo', date: '15 y 16' },
            { locale: 'Nacional Arroyo Hondo', province: 'Santo Domingo', date: '15 y 16' },
            { locale: 'Nacional Tiradentes', province: 'Santo Domingo', date: '15 y 16' },
            { locale: 'Sirena Market Arroyo Hondo', province: 'Santo Domingo', date: '10, 11, 15 y 16' },
            { locale: 'Sirena Galeria 360', province: 'Santo Domingo', date: '10, 11, 12, 13, 15, 16, 30 y 31' },
            { locale: 'Jumbo San Isidro', province: 'Santo Domingo', date: '19 y 20' },
            { locale: 'Jumbo Megacentro', province: 'Santo Domingo', date: '19 y 20' },
            { locale: 'Sirena Charles de Gaulle', province: 'Santo Domingo', date: '12, 13, 19 y 20' },
            { locale: 'Sirena San Isidro', province: 'Santo Domingo', date: '12, 13, 19 y 20' },
            { locale: 'Sirena Venezuela', province: 'Santo Domingo', date: '12, 13, 19 y 20' },
            { locale: 'Nacional Bella Vista', province: 'Santo Domingo', date: '24 y 25' },
            { locale: 'Sirena Sarasota', province: 'Santo Domingo', date: '24 y 25' },
            { locale: 'Plaza Lama 27 Febrero', province: 'Santo Domingo', date: '24 y 25' },
            { locale: 'Ole 27 Febrero', province: 'Santo Domingo', date: '24 y 25' },
            { locale: 'Sirena Lope de Vega', province: 'Santo Domingo', date: '10, 11, 24 y 25' },
            { locale: 'Sirena Aut. Duarte', province: 'Santo Domingo', date: '26 y 27' },
            { locale: 'Sirena Villa Mella', province: 'Santo Domingo', date: '26 y 27' },
            { locale: 'Plaza Lama Duarte', province: 'Santo Domingo', date: '26 y 27' },
            { locale: 'Plaza Lama Aut. Duarte', province: 'Santo Domingo', date: '26 y 27' },
            { locale: 'Ole Duarte', province: 'Santo Domingo', date: '26 y 27' },
        ]
    },

]