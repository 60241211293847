import axios from 'axios';


// Define the API root url

import { API_URL } from './constants';


// Setup Axios Instance

const api = axios.create({
    baseURL: API_URL
});

export default api;