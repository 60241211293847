import React, {useEffect, useState} from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

// Pages

import Home from "./pages/Home/Home";
import Calendar from "./pages/Calendar/Calendar";
// import Register from "./pages/Register/Register";
// import Prize from "./pages/Prize/Prize";
import Winners from "./pages/Winners/Winners";
import Products from "./pages/Products/Products";
import Contact from "./pages/Contact/Contact";

// Components

import AudioPlayer from './components/AudioPlayer/AudioPlayer';

function App() {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // useEffect(() => {
  //   const checkTime = () => {
  //     const date = new Date();
  //     const hours = date.getHours();
  //     const minutes = date.getMinutes();
      
  //     if (hours > 23 || (hours === 23 && minutes >= 59)) {
  //       setIsButtonDisabled(true);
  //     }
  //   };

  //   checkTime();

  //   const interval = setInterval(checkTime, 60000);

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <>
      <Router>
        <AudioPlayer/>
        <Routes>
          <Route exact path="/" element={<Home isButtonDisabled={isButtonDisabled}/>} />
          {/* {!isButtonDisabled && <Route path="/registro" element={<Register/>} />} */}
          {/*<Route path="/registro" element={<Register/>} />*/}
          {/* <Route path="/premio" element={<Prize/>} /> */}
          <Route path="/productos" element={<Products/>} />
          <Route path="/calendario" element={<Calendar/>} />
          <Route path="/ganadores" element={<Winners/>} />
          <Route path="/contacto" element={<Contact/>} />
          <Route path="*" element={<Navigate to ="/" />}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
