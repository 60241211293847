export const CALENDAR_INDEPENDENT_DATA = [
    { 
        month: 'Mayo',
        activations: [
            { locale: 'SUPER 1', province: 'Santo Domingo', date: '10 y 14' },
            { locale: 'MINI1', province: 'Santo Domingo', date: '10 y 14' },
            { locale: 'SM BUENA VISTA', province: 'Santo Domingo', date: '10 y 14' },
            { locale: 'DIAMANTE', province: 'Santo Domingo', date: '10 y 14' },
            { locale: 'PRESTIGIO', province: 'Santo Domingo', date: '10 y 14' },
            { locale: 'S.M. PRECIO JUSTO', province: 'Santo Domingo', date: '11' },
            { locale: 'S.M. CARIBE II', province: 'Santo Domingo', date: '11' },
            { locale: 'LIVERPOOL', province: 'Santo Domingo', date: '11' },
            { locale: 'ALMACENES UNIDOS, SAS', province: 'Santo Domingo', date: '11' },
            { locale: 'SUPERMERCADO 100 %', province: 'Santo Domingo', date: '11' },
            { locale: 'EL INDIO', province: 'Santo Domingo', date: '15 y 16' },
            { locale: 'SABERY', province: 'Santo Domingo', date: '15 y 16' },
            { locale: 'ECONOMIA2', province: 'Santo Domingo', date: '15 y 16' },
            { locale: 'LA PALMA', province: 'Santo Domingo', date: '15 y 16' },
            { locale: 'REAL SUPER MARKET', province: 'Santo Domingo', date: '15 y 16' },
            { locale: 'COMERCIAL MELISSA', province: 'MONTE PLATA', date: '17' },
            { locale: 'RAMON E. PEREZ VENTURA', province: 'MONTE PLATA', date: '17' },
            { locale: 'S.M. HENG RONG', province: 'HAINA', date: '17' },
            { locale: 'S. M. PRISCILA', province: 'SAN CRISTOBAL', date: '17' },
            { locale: 'SUPERMERCADO C MELLA SRL', province: 'Santo Domingo', date: '17' },
            { locale: 'S.M. BUEN PRECIO E.I.R.L.', province: 'Santo Domingo', date: '18' },
            { locale: 'SUPERMERCADO SOBERANO, SRL', province: 'Santo Domingo', date: '18' },
            { locale: 'SOLUCIONES VETERINARIAS SRL', province: 'Santo Domingo', date: '18' },
            { locale: 'SUPER MERCADO HOY NG SRL', province: 'Santo Domingo', date: '18' },
            { locale: 'SUPERMERCADO SAOMY, SRL', province: 'SAN CRISTOBAL', date: '22 y 23' },
            { locale: 'MINI MARKET MATEO, SRL.', province: 'SAN CRISTOBAL', date: '22 y 23' },
            { locale: 'SUPERMERCADO HASAN SRL', province: 'HAINA', date: '22 y 23' },
            { locale: 'TIENDA LA GRAN MARAVILLA EIRL', province: 'BANI', date: '22 y 23' },
            { locale: 'SUPERMERCADO JOEL Y JIMENEZ SRL', province: 'VILLA ALTAGRACIA', date: '22 y 23' },
            { locale: 'FERNANDEZ GARRIDO, SAS', province: 'SANTO DOMINGO', date: '24 y 25' },
            { locale: 'SUPERMERCADO EL POTENTE', province: 'SANTO DOMINGO', date: '24 y 25' },
            { locale: 'PLAZA LEONARDO, SRL', province: 'SANTO DOMINGO', date: '29 y 30' },
            { locale: 'S.M. CASA EDWIN', province: 'SANTO DOMINGO', date: '29 y 30' },
            { locale: 'SUPERMERCADO M. J. CUMBRE SRL', province: 'SANTO DOMINGO', date: '29 y 30' },
            { locale: 'GRUPO PRISTINE SRL', province: 'SANTO DOMINGO', date: '29 y 30' },
            { locale: 'S.M. CARIBE', province: 'SANTO DOMINGO', date: '29 y 30' },
            { locale: 'S.M. AVANZADO', province: 'SANTO DOMINGO', date: '18 y 31' },
            { locale: 'ALMACENES EL BARATILLO SRL', province: 'SAN PEDRO MACORIS', date: '31' },
            { locale: 'COL. DANNY', province: 'SAN PEDRO MACORIS', date: '31' },
            { locale: 'ALMACENES EL BARATILLO SRL', province: 'SAN PEDRO MACORIS', date: '31' },
            { locale: 'COL. DANNY', province: 'SAN PEDRO MACORIS', date: '31' },
        ]
    },
    { 
        month: 'Junio',
        activations: [
            { locale: 'S.M. AVANZADO', province: 'SANTO DOMINGO', date: '1' },
            { locale: 'ALMACENES EL BARATILLO SRL', province: 'SAN PEDRO MACORIS', date: '1' },
            { locale: 'COL. DANNY', province: 'SAN PEDRO MACORIS', date: '1' },
            { locale: 'ALMACENES EL BARATILLO SRL', province: 'SAN PEDRO MACORIS', date: '1' },
            { locale: 'COL. DANNY', province: 'SAN PEDRO MACORIS', date: '1' },
            { locale: 'SUPERMERCADO PITUFA', province: 'HIGUEY', date: '5' },
            { locale: 'COMERCIAL SANTANA GUERRERO 1 FRANSG', province: 'HIGUEY', date: '5' },
            { locale: 'COMERCIAL CARNICENTRO REYITO', province: 'BAVARO', date: '5' },
            { locale: 'ALMACENES DEL ESTE, SA', province: 'LA ROMANA', date: '7 y 8' },
            { locale: 'COMERCIAL LA ECONOMIA II', province: 'PUNTA CANA', date: '7 y 8' },
            { locale: 'INVERSIONES JJK&O SRL', province: 'LAS TERRENAS', date: '7 y 8' },
            { locale: 'COMPAÑIA LORA PEREYRA, C. POR A.', province: 'AZUA', date: '12 y 13' },
            { locale: 'EMPRESA GONZALEZ TAVARES Y ASOCIADO', province: 'BARAHONA', date: '12 y 13' },
            { locale: 'COMERCIAL CORNIELLE SUBERBI', province: 'BARAHONA', date: '12 y 13' },
            { locale: 'FARMACIA ANA ISABEL', province: 'BARAHONA', date: '12 y 13' },
            { locale: 'TIENDA LA GRAN MARAVILLA EIRL', province: 'BANI', date: '14 y 15' },
            { locale: 'EMPRESA GONZALEZ TAVARES Y ASOCIADO', province: 'BARAHONA', date: '14 y 15' },
            { locale: 'COMERCIAL CORNIELLE SUBERBI', province: 'BARAHONA', date: '14 y 15' },
            { locale: 'FARMACIA ANA ISABEL', province: 'BARAHONA', date: '14 y 15' },
            { locale: 'SUPERMERCADO LA FUENTE FUN', province: 'SANTIAGO', date: '19 y 20' },
            { locale: 'HIPERMERCADO LA FUENTE', province: 'SANTIAGO', date: '19 y 20' },
            { locale: 'ALMACENES EL ENCANTO', province: 'SANTIAGO', date: '19 y 20' },
            { locale: 'SUPER PLAZA VENEZUELA', province: 'SANTIAGO', date: '19 y 20' },
            { locale: 'SUPERMERCADO CENTRAL SAS', province: 'SANTIAGO', date: '19 y 20' },
            { locale: 'RITA VARIEDADES SRL', province: 'SANTIAGO', date: '21' },
            { locale: 'ALM. DE PROV. PLAZA VALERIO HERNAND', province: 'SANTIAGO', date: '21' },
            { locale: 'SUPERMERCADO UREÑA MINIER, SRL', province: 'SANTIAGO', date: '21' },
            { locale: 'S.M. PROV. NUÑEZ', province: 'SANTIAGO', date: '21' },
            { locale: 'S.M. CASA DEL PUEBLO, SRL', province: 'SANTIAGO', date: '21' },
            { locale: 'SUPERMERCADO ESTRELLA VRA SRL', province: 'SANTIAGO', date: '22' },
            { locale: 'ALMACENES LA FERIA', province: 'SANTIAGO', date: '22' },
            { locale: 'S.M. PADILLA', province: 'SAN FRANCISCO MACORIS', date: '22' },
            { locale: 'CASA CARLOS', province: 'BONAO', date: '22' },
            { locale: 'SM LA BENDICION DE CRISTO BRISEB SR', province: 'BONAO', date: '22' },
            { locale: 'SUPERMERCADO VEGA REAL SRL', province: 'LA VEGA', date: '26' },
            { locale: 'SUPERMERCADO GRULLON ROMANO SRL', province: 'LA VEGA', date: '26' },
            { locale: 'COOPCIBAO', province: 'MOCA', date: '26' },
            { locale: 'SUPERMERCADO L & C', province: 'MOCA', date: '26' },
            { locale: 'SUPERMERCADO EDWARD SRL', province: 'MAO VALVERDE', date: '26' },
            { locale: 'SUPERMERCADO EL GRAN PORVENIR S R L', province: 'SAN FRANCISCO MACORIS', date: '27 y 28' },
            { locale: 'ALM ALMANZAR', province: 'SAN FRANCISCO MACORIS', date: '27 y 28' },
            { locale: 'ASFER SRL', province: 'CONSTANZA', date: '27 y 28' },
            { locale: 'S.M. AQUILES MOREL', province: 'MAO VALVERDE', date: '27 y 28' },
            { locale: 'ALMACEN SAN MIGUEL', province: 'COTUI', date: '27 y 28' },
            { locale: 'CESAR & CESAR C&C ASOCIADOS SRL', province: 'NAGUA', date: '29 y 30' },
            { locale: 'SURTIDORA ELVIN', province: 'SALCEDO', date: '29 y 30' },
            { locale: 'S. M. RODRIGUEZ SURIEL SRL', province: 'GASPAR HERNANDEZ', date: '29 y 30' },
            { locale: 'CASA DURAN', province: 'JARABACOA', date: '30' },
            { locale: 'S.M. EL ECONOMICO LA COMPETENCIA SR', province: 'CONSTANZA', date: '30' },
        ]
    },
    {
        month: 'Julio',
        activations: [
            { locale: 'SUPERMERCADO MAMA LOLA SRL', province: 'COTUI', date: '3' },
            { locale: 'S.M. EL REGUERO', province: 'JARABACOA', date: '2' },
            { locale: 'PROVISIONES ELVIN HERNANDEZ', province: 'SALCEDO', date: '3' },
            { locale: 'BODEGA Y SURTIDORA SAMANA SRL', province: 'SAMANA', date: '3' },
            { locale: 'PLAZA AGROPECUARIA MAMATINGO SRL', province: 'SAN FRANCISCO MACORIS', date: '3' },
            { locale: 'S.M. COMPRES, SRL', province: 'MOCA', date: '4' },
            { locale: 'SUPERMERCADO JARABACOA, SRL', province: 'JARABACOA', date: '4' },            
            { locale: 'SUPER 1', province: 'Santo Domingo', date: '6' },
            { locale: 'MINI1', province: 'Santo Domingo', date: '6' },
            { locale: 'SM BUENA VISTA', province: 'Santo Domingo', date: '6' },
            { locale: 'DIAMANTE', province: 'Santo Domingo', date: '6' },
            { locale: 'PRESTIGIO', province: 'Santo Domingo', date: '6' },
            { locale: 'EL INDIO', province: 'Santo Domingo', date: '10' },
            { locale: 'SABERY', province: 'Santo Domingo', date: '10' },
            { locale: 'ECONOMIA2', province: 'Santo Domingo', date: '10' },
            { locale: 'LA PALMA', province: 'Santo Domingo', date: '10' },
            { locale: 'REAL SUPER MARKET', province: 'Santo Domingo', date: '10' },
            { locale: 'SUPERMERCADO SAOMY, SRL', province: 'SAN CRISTOBAL', date: '12' },
            { locale: 'MINI MARKET MATEO, SRL.', province: 'SAN CRISTOBAL', date: '12' },
            { locale: 'SUPERMERCADO HASAN SRL', province: 'HAINA', date: '12' },
            { locale: 'TIENDA LA GRAN MARAVILLA EIRL', province: 'BANI', date: '12' },
            { locale: 'SUPERMERCADO JOEL Y JIMENEZ SRL', province: 'VILLA ALTAGRACIA', date: '12' },
            { locale: 'FERNANDEZ GARRIDO, SAS', province: 'SANTO DOMINGO', date: '15 y 31' },
            { locale: 'SUPERMERCADO EL POTENTE', province: 'SANTO DOMINGO', date: '15 y 31' },
            { locale: 'PLAZA LEONARDO, SRL', province: 'SANTO DOMINGO', date: '19 y 25' },
            { locale: 'J & M COMERCIAL', province: 'AZUA', date: '19' },
            { locale: 'GENOVEVA D. DE LA CRUZ V / COM. ENM', province: 'AZUA', date: '19' },
            { locale: 'J & M COMERCIAL', province: 'AZUA', date: '19' },
            { locale: 'GENOVEVA D. DE LA CRUZ V / COM. ENM', province: 'AZUA', date: '19' },
            { locale: 'S.M. CASA EDWIN', province: 'SANTO DOMINGO', date: '25' },
            { locale: 'SUPERMERCADO M. J. CUMBRE SRL', province: 'SANTO DOMINGO', date: '25' },
            { locale: 'GRUPO PRISTINE SRL', province: 'SANTO DOMINGO', date: '25' },
            { locale: 'S.M. CARIBE', province: 'SANTO DOMINGO', date: '25' },
            { locale: 'S.M. AVANZADO', province: 'SANTO DOMINGO', date: '30' },
            { locale: 'ALMACENES EL BARATILLO SRL', province: 'SAN PEDRO MACORIS', date: '30' },
            { locale: 'COL. DANNY', province: 'SAN PEDRO MACORIS', date: '30' },
            { locale: 'ALMACENES EL BARATILLO SRL', province: 'SAN PEDRO MACORIS', date: '30' },
            { locale: 'COL. DANNY', province: 'SAN PEDRO MACORIS', date: '30' },

        ]
    },

]